<template>
    <st-page :title="$t('MENU.DASHBOARD')" layout="section">
        <dashboard-staff v-if="isStaff"/>
        <dashboard-legal-entity v-else-if="isLegalEntity"/>
    </st-page>
</template>

<script>
import {  mapGetters } from 'vuex';

export default {
    name: 'Dashboard',
    components: {
        DashboardLegalEntity: () => import('@/modules/dashboard/pages/DashboardLegalEntity.vue'),
        DashboardStaff: () => import('@/modules/dashboard/pages/DashboardStaff.vue'),
    },
    computed: {
        ...mapGetters({
           isStaff: 'auth/isStaff',
           isLegalEntity: 'auth/isLegalEntity',
        }),
    },
}
</script>


